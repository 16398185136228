import React, { useState, useEffect, useReducer } from "react";
import cx from "classnames";
import { graphql } from "gatsby";

import withLayout from "../hoc/withLayout/withLayout";
import { useBroker } from "../utils/servicesApi";
import useLocalization from "../hooks/useLocalization";
import * as Analytics from "../utils/analytics";
import ConfirmationPage from "../components/UI/ConfirmationPage/ConfirmationPage";
import { scrollToTop } from "../utils/helpers";
import { uniq } from "lodash";
import { Button, FormInput, SEO } from "../components";

type SwatchesPageData = any;

interface SwatchesProps {
  data: {
    alamedaapi: {
      swatchesPage: SwatchesPageData;
    };
  };
}

const initialState = { selected: [] };

const addOrRemove = (
  toBeAdded: string,
  list: string[],
  fullList: { primary: string }[]
): string[] => {
  let arr = list.filter(item => item !== "All Fabrics");
  let idx = arr.indexOf(toBeAdded);
  if (idx === -1) {
    arr.push(toBeAdded);
  } else {
    arr.splice(idx, 1);
  }
  if (arr.length === fullList.length - 1) {
    arr.push("All Fabrics");
  }
  return uniq(arr);
};

function swatchReducer(state, action) {
  switch (action.type) {
    case "init":
      return { selected: action.payload };
    case "click":
      let selected: any = [];
      if (action.payload === "All Fabrics") {
        if (state.selected.length < action.swatches.length - 1) {
          selected = action.swatches.map(item => item.primary);
        } else {
          selected = [];
        }
      } else {
        selected = addOrRemove(action.payload, state.selected, action.swatches);
      }
      return { selected };
    default:
      return state;
  }
}

const Swatches = ({
  data: {
    alamedaapi: { swatchesPage },
  },
}: SwatchesProps) => {
  const [state, dispatch] = useReducer(swatchReducer, initialState);
  const [formState, setFormState] = useState<Record<string, any>>();
  const [formError, setFormError] = useState();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);

  const { addlConfig, locale, territories, regionalCopy } = useLocalization();

  const currentCountryHash = territories.states;

  useEffect(() => {
    const swatchesSelectedByDefault = swatchesPage.swatches
      .filter(swatch => swatch.selectByDefault)
      .map(swatch => swatch.primary);

    dispatch({
      type: "init",
      payload: swatchesSelectedByDefault,
    });

    Analytics.trackPage("Swatch Request Session");
  }, []);

  if (!swatchesPage) return null;

  const onFinish = e => {
    e.preventDefault();
    setLoading(true);
    const payload: Record<string, any> = {
      ...formState,
      color: "Both", // Hardcoded to process both colors shipping
      locale: locale.code,
    };

    useBroker({
      source: "swatchRequest",
      routing: [
        `${addlConfig.klayvioPrefix}:${swatchesPage.klaviyoList}`,
        "swatchOrderControl",
      ],
      payload,
    })
      .then(res => {
        if (res.status !== 201) {
          throw res;
        }
        setLoading(false);
        setStep(1);
        Analytics.track("Swatch request submitted", {
          selectedSwatches: state.selected,
        });
        Analytics.track("Lead Capture", { source: "swatchRequest" });
        Analytics.identify(payload.email, {
          email: payload.email,
          firstName: payload.firstName,
          lastName: payload.lastName,
          swatchRequester: true,
        });
        scrollToTop();
      })
      .catch(err => {
        setLoading(false);
        setFormError(err);
        console.log(err);
      });
  };

  const handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const sortedSwatches = swatchesPage.swatches.sort((a, b) => {
    const aPriority = a.priority ?? Infinity;
    const bPriority = b.priority ?? Infinity;
    return aPriority - bPriority;
  });

  return (
    <>
      <SEO title="Select your fabric swatches" />
      <div>
        {step === 0 ? (
          <div className="component-default-padding">
            <form
              onSubmit={e => {
                onFinish(e);
                return false;
              }}
            >
              <div className="flex flex-col md:mb-5 md:flex-row md:gap-24 lg:gap-32">
                <div className="grow-[5] basis-0">
                  <h1 className="mb-5 text-xl md:text-2xl">
                    {swatchesPage.primary}
                  </h1>
                  <div className="grid grid-cols-2 gap-4 gap-y-6">
                    {sortedSwatches.map((swatch, i) => (
                      <label key={i}>
                        <div
                          className={cx("w-full", {
                            "border-2 border-primary-color":
                              state.selected.includes(swatch.primary),
                          })}
                        >
                          <div className="relative">
                            <div className="md:aspect-h-2 md:aspect-w-3">
                              <img
                                src={swatch.image.formats.medium.url}
                                alt={swatch.primary}
                              />
                            </div>
                            <div className="absolute bottom-2 left-2">
                              <input
                                type="checkbox"
                                checked={state.selected.includes(
                                  swatch.primary
                                )}
                                onChange={() =>
                                  dispatch({
                                    type: "click",
                                    payload: swatch.primary,
                                    swatches: swatchesPage.swatches,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <span className="mt-3 block">{swatch.primary}</span>
                      </label>
                    ))}
                  </div>
                </div>
                <div className="grow-[4] basis-0">
                  <div className="grid grid-cols-1 gap-6">
                    <h2 className="mt-8 text-lg md:mt-0 md:text-2xl">
                      {swatchesPage.secondary}
                    </h2>
                    <div className="flex w-full flex-col gap-5 md:flex-row">
                      <FormInput
                        value={formState?.firstName}
                        onChange={handleInputChange}
                        required
                        label="First Name"
                        name="firstName"
                        type="text"
                      />
                      <FormInput
                        value={formState?.lastName}
                        onChange={handleInputChange}
                        required
                        label="Last Name"
                        name="lastName"
                        type="text"
                      />
                    </div>

                    <FormInput
                      value={formState?.email}
                      onChange={handleInputChange}
                      required
                      label="Email"
                      name="email"
                      type="email"
                      validationMessage="Please enter a valid email address."
                    />
                    <FormInput
                      value={formState?.phoneNumber}
                      onChange={handleInputChange}
                      required
                      label="Phone Number"
                      name="phoneNumber"
                      type="tel"
                      validationMessage={
                        regionalCopy.phoneNumberValidationRules.message
                      }
                      pattern={regionalCopy.phoneNumberValidationRules.pattern}
                      min={regionalCopy.phoneNumberValidationRules.min}
                    />
                    <FormInput
                      value={formState?.street}
                      onChange={handleInputChange}
                      required
                      label="Address"
                      name="street"
                      type="text"
                    />

                    <div className="flex w-full flex-col gap-5 md:flex-row">
                      <FormInput
                        value={formState?.streetSecondary}
                        onChange={handleInputChange}
                        label="Apt/unit"
                        name="streetSecondary"
                        type="text"
                      />
                      <FormInput
                        value={formState?.state}
                        onChange={handleInputChange}
                        label={regionalCopy.stateProvinceText}
                        name="state"
                        type="select"
                        required
                        options={currentCountryHash}
                      />
                    </div>
                    <div className="flex w-full flex-col gap-5 md:flex-row">
                      <FormInput
                        value={formState?.city}
                        onChange={handleInputChange}
                        label={regionalCopy.cityText}
                        name="city"
                        type="text"
                        required
                      />
                      <FormInput
                        value={formState?.zipCode}
                        onChange={handleInputChange}
                        label={regionalCopy.zipCodeText}
                        name="zipCode"
                        type="text"
                        required
                      />
                    </div>
                    <Button
                      type="primary"
                      loading={loading}
                      className="mt-5 w-full"
                      htmlType="submit"
                      disabled={formError || !state.selected.length}
                    >
                      Submit & Continue
                    </Button>
                  </div>
                  {!state.selected.length && (
                    <p className="mt-5 text-outer-feedback-error">
                      Please select a swatch
                    </p>
                  )}
                  {formError && (
                    <p className="mt-5 text-outer-feedback-error">
                      We seem to have hit a snag. Please try your request again.
                    </p>
                  )}
                </div>
              </div>
            </form>
          </div>
        ) : (
          swatchesPage.form_confirmation && (
            <ConfirmationPage
              title={swatchesPage.form_confirmation.primary}
              subtitle={swatchesPage.form_confirmation.secondary}
              body={swatchesPage.form_confirmation.body}
            />
          )
        )}
      </div>
    </>
  );
};

export default withLayout(Swatches);

export const swatchesQuery = graphql`
  query SWATCHES_QUERY($locale: String!) {
    alamedaapi {
      swatchesPage(locale: $locale) {
        primary
        secondary
        klaviyoList
        form_confirmation {
          primary
          secondary
          body
        }
        swatches(limit: 10) {
          primary
          secondary
          priority
          selectByDefault
          image {
            formats
          }
        }
      }
    }
  }
`;
